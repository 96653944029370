import { AuthLogic } from "@/src/model";
import { Log } from "@/src/util/log";

export type HTTPResponse<T> = {
  success: boolean;
  status: number;
  error?: string;
  body?: T;
};

export class HTTP<T> {
  _url: string;
  _method: string;
  _body: any;
  _useToken: boolean;
  _overrideNoToken: boolean;
  _allEnvId: string | undefined;
  _allowGuest: boolean;
  _v1Authorization: boolean;
  _Authorization: string;

  constructor(
    url: string,
    method: string,
    body: any = null,
    useToken: boolean = true
  ) {
    this._url = url;
    this._method = method;
    this._body = body;
    this._useToken = useToken;
    this._overrideNoToken = false;
    this._allowGuest = false;
    this._v1Authorization = false;
    this._Authorization = "";
  }

  setOverrideNotToken(override: boolean) {
    this._overrideNoToken = override;
  }

  setAllEnvId(allEnvId: string) {
    this._allEnvId = allEnvId;
  }

  setAllowGuest(allowGuest: boolean) {
    this._allowGuest = allowGuest;
  }

  setUseV1Authorization(v1Authorization: boolean) {
    this._v1Authorization = v1Authorization;
  }
  
  setUseCustomAuthorization(authorization: string) {
    this._Authorization = authorization;
  }

  execute = async (signal?: AbortSignal): Promise<HTTPResponse<T>> => {
    return await new Promise(async resolve => {
      interface Headers {
        "Content-Type": string;
        Authorization?: string;
        Guest?: string;
        AllEnvId?: string;
        "V1-Authorization"?: string | null;
      }

      const headers: Headers = {
        "Content-Type": "application/json",
        AllEnvId: this._allEnvId,
        "V1-Authorization": this._v1Authorization
          ? AuthLogic.getIdentityToken()
          : undefined,
      };

      if (this._useToken) {
        const token: string | undefined | null = AuthLogic.getV2AuthToken();
        const guestToken: string | undefined | null = AuthLogic.getGuestToken();
        const isGuest: boolean = typeof guestToken === "string";

        //if no token is available return 401
        //unless
        //1. method is set as override no token
        //2. isGuest token is set && method is set to allow guest
        if (
          (!token || token === "undefined" || (token?.length ?? 0) < 30) &&
          !this._overrideNoToken &&
          !isGuest &&
          this._allowGuest
        ) {
          Log.logToConsoleDebug("HTTP.tsx", "API REQUEST", [
            this._url,
            "no token",
            token,
          ]);
          resolve({ success: false, status: 401, error: "NO_TOKEN" });
          return;
        }

        //if a token is present set the token (this is SSO auth provider token)
        if (token) {
          headers.Authorization = `Bearer ${token}`;
        }

        //if a guest token is present set the guest token
        if (guestToken) {
          headers.Guest = `Bearer ${guestToken}`;
        }
      } else if (this._Authorization) {
        headers.Authorization = this._Authorization;
      }

      const request = {
        method: this._method,
        headers: headers,
        body:
          this._body && this._method !== "GET"
            ? JSON.stringify(this._body)
            : undefined,
      };

      const returnResponse = (toResolve: HTTPResponse<T>) => {
        Log.logToConsoleDebug("HTTP.ts", "API REQUEST", [
          this._url,
          request,
          toResolve,
        ]);
        if (!toResolve.success && toResolve.status !== 404) {
          Log.logToDataDog(Log.LogLevel.ERROR, "HTTP.ts", "API REQUEST ERROR", [
            this._url,
            request,
            toResolve,
          ]);
        }
        resolve(toResolve);
      };

      try {
        //@ts-ignore
        fetch(this._url, request, { signal })
          .then(response => {
            const { status } = response;
            switch (status) {
              case 200:
                response.json().then(response => {
                  returnResponse({ success: true, status, body: response });
                  return;
                });
                return;
              case 201:
              case 202:
              case 203:
              case 204:
                returnResponse({ success: true, status });
                return;
              case 404:
                returnResponse({ success: false, status });
                return;
              default:
                response.text().then(response => {
                  returnResponse({ success: false, status, error: response });
                  return;
                });
                return;
            }
          })
          .catch(error => {
            returnResponse({ success: false, status: 500, error });
          });
      } catch (error: any) {
        returnResponse({ success: false, status: 599, error });
      }
    });
  };

  static methods = {
    GET: "GET",
    PUT: "PUT",
    POST: "POST",
    PATCH: "PATCH",
    DELETE: "DELETE",
  };
}
