import React, { ChangeEvent, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";
import cn from "classnames";
import { useDebouncedCallback } from "use-debounce";
import { BaseButton, BaseIcon, BaseSelect } from "@/src/component/base";
import { useApp } from "@/src/component/provider";
import { useBreakPointDown } from "@/src/hook";
import { SearchLogic } from "@/src/model";
import { BlaceV2Type } from "@/src/type";
import { AppSearchFilterOption } from "@/src/type/AppSearchFilterType";
import { VendorQuickFilterValues, VenueQuickFilterValues } from "@/src/type/component/Listing";
import { NumberHelper } from "@/src/util";
import styles from "./QuickFilters.module.scss";

interface QuickFiltersProps {
  onSearchClick?: () => void;
  listingType: BlaceV2Type.SearchTypes;
}

interface AppliedFilters {
  guestCount: string;
  bestUsedFor: AppSearchFilterOption | null;
  vendorType: string;
  vendorPricing: string;
  region: string;
}

function QuickFilters({ onSearchClick, listingType }: QuickFiltersProps) {
  const app = useApp();

  const [filters, setFilters] = useState<AppliedFilters>({
    guestCount: "",
    bestUsedFor: null,
    vendorType: "",
    vendorPricing: "",
    region: "",
  });

  const isMobile = useBreakPointDown("md");

  const eventTypeOptions = useMemo(() => SearchLogic.FILTERS.bestUsedFor.options || [], []);
  const regionOptions = useMemo(() => SearchLogic.FILTERS.regions.options || [], []);
  const vendorTypeOptions = useMemo(() => SearchLogic.FILTERS.vendorType.options || [], []);
  const vendorPricingOptions = useMemo(() => SearchLogic.FILTERS.vendorPricing.options || [], []);

  const eventTypeValue =
    eventTypeOptions.find(
      (el) =>
        el.value ===
        (isMobile
          ? filters.bestUsedFor?.value
          : app.searchFilters?.getFilterData(VenueQuickFilterValues.BEST_USED_FOR)),
    ) || null;

  const regionSelectValue = isMobile
    ? filters.region
    : (app.searchFilters?.getFilterData(VenueQuickFilterValues.REGIONS) ?? "");

  const vendorTypeSelectValue = isMobile
    ? filters.vendorType
    : (app.searchFilters?.getFilterData(VendorQuickFilterValues.TYPE) ?? "");

  const vendorPricingSelectValue = isMobile
    ? filters.vendorPricing
    : (app.searchFilters?.getFilterData(VendorQuickFilterValues.PRICING) ?? "");

  const setSingleChoice = (changeTo: string, dataKey: string) => {
    const currentFilter = app.searchFilters?.getFilterData(dataKey) ?? "";
    const newValue = changeTo === currentFilter ? "" : changeTo;
    app.searchFilters?.setFilterData(dataKey, newValue);
    SearchLogic.trackFilterUsed(changeTo, dataKey, "QuickFilters.tsx");
  };

  const debounceGuestValue = useDebouncedCallback((guestValue: string) => {
    app.searchFilters?.setFilterData(VenueQuickFilterValues.CAPACITY, guestValue);
    SearchLogic.trackFilterUsed(guestValue, VenueQuickFilterValues.CAPACITY, "QuickFilters.tsx");
  }, 500);

  const handleGuestCountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const guestValue = NumberHelper.clearInputValue(event.target.value);
    setFilters({ ...filters, guestCount: guestValue });
    if (!isMobile) {
      debounceGuestValue(guestValue);
    }
  };

  const setFilterValue = (key: keyof AppliedFilters, value: string, dataKey: string) => {
    if (isMobile) {
      setFilters((prev) => ({ ...prev, [key]: value }));
      return;
    }
    setSingleChoice(value, dataKey);
  };

  const handleEventTypeChange = (e: SyntheticEvent, val: AppSearchFilterOption | null) => {
    if (isMobile) {
      setFilters((prev) => ({ ...prev, bestUsedFor: val }));
      return;
    }
    setSingleChoice(val?.value || "", VenueQuickFilterValues.BEST_USED_FOR);
  };

  const handleMobileSearch = () => {
    const newFilterData =
      listingType === BlaceV2Type.SearchTypes.Venue
        ? [
            {
              property: VenueQuickFilterValues.BEST_USED_FOR,
              data: filters.bestUsedFor?.value || "",
            },
            { property: VenueQuickFilterValues.REGIONS, data: filters.region },
            { property: VenueQuickFilterValues.CAPACITY, data: filters.guestCount },
          ]
        : [
            { property: VendorQuickFilterValues.TYPE, data: filters.vendorType || "" },
            { property: VendorQuickFilterValues.REGIONS, data: filters.region },
            { property: VendorQuickFilterValues.PRICING, data: filters.vendorPricing },
          ];
    app.searchFilters?.setFilterMultiData(newFilterData);
    newFilterData.forEach((filterData) =>
      SearchLogic.trackFilterUsed(filterData.data, filterData.property, "QuickFilters.tsx"),
    );
    onSearchClick?.();
  };

  const toggleExtraFilters = (isOpen: boolean) => {
    app.setShowExtraFilters(isOpen);
    app.setSearchFocus(isOpen);
  };

  useEffect(() => {
    if (isMobile) {
      const regionFromUrl = app.searchFilters?.getFilterData<string>(
        VenueQuickFilterValues.REGIONS,
      );
      const vendorTypeFromUrl = app.searchFilters?.getFilterData<string>(
        VendorQuickFilterValues.TYPE,
      );
      const vendorPricingFromUrl = app.searchFilters?.getFilterData<string>(
        VendorQuickFilterValues.PRICING,
      );
      const eventTypeFromUrl = app.searchFilters?.getFilterData<string>(
        VenueQuickFilterValues.BEST_USED_FOR,
      );

      setFilters((prevFilters) => ({
        ...prevFilters,
        bestUsedFor: eventTypeOptions.find((el) => el.value === eventTypeFromUrl) || null,
        vendorType: vendorTypeFromUrl ?? "",
        vendorPricing: vendorPricingFromUrl ?? "",
        region: regionFromUrl ?? "",
      }));
    }

    if (listingType !== BlaceV2Type.SearchTypes.Vendor) {
      const guestFromUrl = app.searchFilters?.getFilterData<string>(
        VenueQuickFilterValues.CAPACITY,
      );
      setFilters((prevFilters) => ({ ...prevFilters, guestCount: guestFromUrl || "" }));
    }
  }, [isMobile, app.searchFilters, eventTypeOptions, listingType]);

  return (
    <Box className={styles.quickFiltersContainer}>
      {listingType !== BlaceV2Type.SearchTypes.Vendor ? (
        <>
          <Autocomplete
            options={eventTypeOptions}
            getOptionLabel={(option) => option.label}
            className={styles.eventSelect}
            value={eventTypeValue}
            onChange={handleEventTypeChange}
            popupIcon={null}
            data-testid="event-type-select"
            renderInput={(params) => (
              <TextField
                placeholder="Event type"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <BaseIcon iconFileName="groupShareIcon" iconAlt="event type" />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <TextField
            placeholder="Guest count"
            className={styles.guestCount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BaseIcon iconFileName="profileGreyIcon" iconAlt="guest count" />
                </InputAdornment>
              ),
            }}
            onChange={handleGuestCountChange}
            value={NumberHelper.formatFormInputValue(filters.guestCount)}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              "data-testid": "guest-count-input",
            }}
          />
        </>
      ) : (
        <>
          <BaseSelect
            id="vendor-type-select"
            data-testid="vendor-type-select"
            className={cn(styles.filtersSelect, styles.vendorTypeSelect)}
            value={vendorTypeSelectValue as string}
            onChange={(e) =>
              setFilterValue("vendorType", e.target.value, VendorQuickFilterValues.TYPE)
            }
            displayEmpty
            defaultPlaceholder="Vendor Type"
            options={vendorTypeOptions}
            startIcon="videoIcon"
            startIconAlt="vendor type selection"
            defaultOption="Any Type"
          />
          <BaseSelect
            id="vendor-pricing-select"
            data-testid="vendor-pricing-select"
            className={cn(styles.filtersSelect, styles.pricingSelect)}
            value={vendorPricingSelectValue as string}
            onChange={(e) =>
              setFilterValue("vendorPricing", e.target.value, VendorQuickFilterValues.PRICING)
            }
            options={vendorPricingOptions}
            startIcon="dollarIcon"
            startIconAlt="vendor pricing selection"
            defaultOption="Any Pricing"
            defaultPlaceholder="Vendor Pricing"
          />
        </>
      )}
      <BaseSelect
        id="city-select"
        data-testid="city-select"
        className={cn(styles.filtersSelect, styles.citySelect)}
        value={regionSelectValue as string}
        onChange={(e) => setFilterValue("region", e.target.value, VendorQuickFilterValues.REGIONS)}
        options={regionOptions}
        startIcon="locationPinOutlinedIcon"
        startIconAlt="location selection"
        defaultOption="Any Location"
        defaultPlaceholder="Location"
      />

      <Box
        className={styles.extraFiltersWrapper}
        onClick={() => toggleExtraFilters(true)}
        data-testid="extra-filters-count"
      >
        <BaseIcon iconFileName="filterIcon" iconAlt="extra filters" iconSize={20} />
        {SearchLogic.countAppliedExtraFilters(listingType, app.searchFilters?.getFilterData) || ""}
      </Box>

      <BaseButton
        onClick={handleMobileSearch}
        className={styles.searchButton}
        data-testid="search-btn"
      >
        Search
      </BaseButton>
    </Box>
  );
}

export default QuickFilters;
