import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { EMAIL_VALIDATION_REGEXP, PHONE_VALIDATION_REGEXP } from "@/src/const";
import { ActivityLogic, AuthLogic } from "@/src/model";
import { BlaceV2API } from "@/src/service";
import { BlaceV2Type } from "@/src/type";
import { Log, sleep } from "@/src/util";
import type { FormikProps } from "formik";

export type GeneralInquiryFormTypeFormik = BlaceV2Type.EventType.Inquiry;

export type UserGeneralInquiryFormType = {
  isLoading: boolean;
  saveComplete: boolean;
  hasError?: string;
  formik: FormikProps<GeneralInquiryFormTypeFormik>;
  inquiryId?: string;
};

const validationSchema = Yup.object().shape({
  clientName: Yup.string()
    .max(255, "Name too long")
    .required("Name is required"),
  eventName: Yup.string()
    .max(255, "Subject too long")
    .required("Subject is required"),
  email: Yup.string()
    .matches(EMAIL_VALIDATION_REGEXP, "Provide a valid email")
    .max(255, "Email too long")
    .required("Email is required"),
  phone: Yup.string()
    .matches(PHONE_VALIDATION_REGEXP, "Only numbers are valid")
    .min(17, "Phone number is too short")
    .max(18, "Phone number is too long")
    .nullable(),
  notes: Yup.string()
    .min(22, "A message is required")
    .max(3999, "Message too long")
    .required("A message is required"),
});

export function useGeneralInquiryForm(): UserGeneralInquiryFormType {
  const [started, setStarted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saveComplete, setSaveComplete] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasError, setHasError] = useState<string | undefined>(undefined);
  const [inquiryId, setInquiryId] = useState<string | undefined>(undefined);

  /**
   * submit email to the backend to save as a lead
   */
  const formik = useFormik<GeneralInquiryFormTypeFormik>({
    enableReinitialize: true,
    initialValues: {
      inquiryStatus: "draft",
      inquiryType: BlaceV2Type.InquiryTypes.General,
      email: "",
      clientName: "",
      budget: "",
      eventName: "",
      guests: "",
      flexibleDates: false,
      startDate: null,
      endDate: null,
      notes: "",
      extras: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);

      const currentUser = AuthLogic.getAuthCurrentUser();
      if (currentUser) {
        values.email = currentUser.email;
        values.clientName = currentUser.name;
      }

      const response = await BlaceV2API.EventServiceV2.postInquirySubmit(values);
      if (response.body?.metadata.statusCode === 200) {
        const thisInquiryId = response.body.payload.inquiryId;

        //track the user
        ActivityLogic.facebookTracking("track", "Lead");
        ActivityLogic.ga4Tracking("general_lead", true);
        await ActivityLogic.toActivityService({
          action: "submitGeneralInqiury",
          actionId: thisInquiryId,
          actionIdType: "inquiry",
          locationInApp: "useGeneralInquiryForm.ts",
        });

        //allow the facebook pixel to fire
        await sleep(150);

        setInquiryId(thisInquiryId);
        setSaveComplete(true);
      } else {
        Log.logToDataDog(Log.LogLevel.ERROR, "useInquiryForm.tsx", "inquiryError", [response.body]);
      }

      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (started) {
      return;
    }

    if (
      formik.values.eventName ||
      formik.values.notes ||
      formik.values.startDate ||
      formik.values.endDate ||
      formik.values.budget ||
      formik.values.guests
    ) {
      ActivityLogic.ga4Tracking("generate_general_lead_initiated", {});
      ActivityLogic.toActivityService({
        action: "generalInquiryInitiated",
        locationInApp: "useGeneralInquiryForm.ts",
      });
      setStarted(true);
    }
  }, [started, formik.values]);

  return {
    isLoading,
    saveComplete,
    hasError,
    formik,
    inquiryId,
  };
}
