import React, { PropsWithChildren } from "react";
import { Paper } from "@mui/material";
import cn from "classnames";
import { DragBar } from "@/src/component/base/DragBar";
import { DraggableComponent } from "@/src/component/base/DraggableComponent";
import { useDragToClose } from "@/src/hook/useDragToClose";
import { MobileListingModalState } from "@/src/type/component/Listing";
import styles from "./DraggableListingView.module.scss";

interface DraggableListingViewProps {
  state: MobileListingModalState;
  onModalDragToBottom: () => void;
  onModalDragToTop: () => void;
}

function DraggableListingView({
  state,
  onModalDragToBottom,
  onModalDragToTop,
  children,
}: PropsWithChildren<DraggableListingViewProps>) {
  const { dragId, handleDrag, handleDragStop } = useDragToClose({
    onDragStop: onModalDragToBottom,
    onDragStopOnTop: onModalDragToTop,
  });

  return (
    <DraggableComponent handleStop={handleDragStop} handleDrag={handleDrag} dragId={`#${dragId}`}>
      <Paper
        className={cn(styles.listingModal, state)}
      >
        <DragBar dragId={dragId} />
        {children}
      </Paper>
    </DraggableComponent>
  );
}

export default DraggableListingView;
