import { DEFAULT_LOCALE } from "@/src/const";
import { StrapiCMSType } from "@/src/type";
import { StrapiService } from "./";

export async function getGlobalWebConfig(locale?: string) {
  const thisLocale = !locale || locale === "undefined" ? DEFAULT_LOCALE : locale;
  return await StrapiService.get<StrapiCMSType.GlobalWebConfigResponse>(
    "global-web-config",
    thisLocale
  );
}

export async function getLandingPageData(locale?: string, slug?: string | string[]) {
  const thisLocale = !locale || locale === "undefined" ? DEFAULT_LOCALE : locale;
  return await StrapiService.get<StrapiCMSType.LandingPageType>(
    "landing-pages",
    thisLocale,
    `filters[slug]=${slug}`
  );
}
