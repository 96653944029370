import { Pagination, PaginationItem } from "@mui/material";
import cn from "classnames";
import Link from "next/link";
import { LISTINGS_PAGE_URL_KEY } from "@/src/const";
import { useNextNavigation } from "@/src/hook";
import styles from "./PaginationSection.module.scss";

interface Props {
  pages: number;
  currentPage: number;
  isPositionFixed: boolean;
}

const PaginationSection = ({
  pages,
  currentPage,
  isPositionFixed
}: Props) => {
  const nextNavigation = useNextNavigation();

  function getToLinkForPagination(page: number): string {
    const params = new URLSearchParams(nextNavigation?.searchParams?.toString());
    params.set(LISTINGS_PAGE_URL_KEY, `${page}`);
    return `${nextNavigation?.pathname}?${params?.toString()}`;
  }

  return (
    <Pagination
      className={cn(styles.listingPagination, {
        fixed: isPositionFixed
      })}
      count={pages}
      shape="rounded"
      size="large"
      siblingCount={1}
      boundaryCount={0}
      page={currentPage}
      data-testid="pagination"
      renderItem={(item) => {
        const isDisabled = item.page === null || item.page < 1 || item.page > pages;

        return isDisabled ? (
          <PaginationItem {...item} disabled />
        ) : (
          <Link href={getToLinkForPagination(item.page ?? 1)} className={styles.listingPaginationOption}>
            <PaginationItem {...item} />
          </Link>
        );
      }}
    />
  );
};

export default PaginationSection;
