import type { MouseEvent } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import cn from "classnames";
import { BaseIcon } from "@/src/component/base";
import { useApp } from "@/src/component/provider";
import {
  VENDOR_LISTINGS_EXTRA_FILTERS,
  VENDOR_LISTINGS_QUICK_FILTERS,
  VENUE_LISTINGS_EXTRA_FILTERS,
  VENUE_LISTINGS_QUICK_FILTERS,
} from "@/src/const";
import { SearchLogic } from "@/src/model";
import { BlaceV2Type } from "@/src/type";
import styles from "./DataTypeButtonGroup.module.scss";

interface DataTypeButtonGroupProps {
  isCitySelected: boolean;
  listingType: BlaceV2Type.SearchTypes;
}

function DataTypeButtonGroup({
  // isCitySelected,
  listingType,
}: DataTypeButtonGroupProps) {
  const app = useApp();

  const handleDataTypeChange = (
    event: MouseEvent<HTMLElement>,
    clickDataType: BlaceV2Type.SearchType.SearchDataType,
  ) => {
    const newDataType =
      clickDataType !== listingType
        ? clickDataType
        : clickDataType === BlaceV2Type.SearchTypes.Venue
          ? BlaceV2Type.SearchTypes.Vendor
          : BlaceV2Type.SearchTypes.Venue;

    const filtersToClear =
      newDataType === BlaceV2Type.SearchTypes.Vendor
        ? [...VENUE_LISTINGS_EXTRA_FILTERS, ...VENUE_LISTINGS_QUICK_FILTERS]
        : [...VENDOR_LISTINGS_EXTRA_FILTERS, ...VENDOR_LISTINGS_QUICK_FILTERS];
    const clearedFilters: Record<string, string[]> = filtersToClear.reduce(
      (filter, value) => ({ ...filter, [value]: [] }),
      {},
    );
    clearedFilters.dataType = [newDataType];
    app.searchFilters?.setMultiChoiceV2(clearedFilters);
    SearchLogic.trackFilterUsed(newDataType, "dataType", "DataTypeButtonGroup.tsx");
  };

  const getToggleImgSrc = (value: BlaceV2Type.SearchType.SearchDataType) => {
    return listingType === value ? `${value}ToggleTabDarkIcon` : `${value}ToggleTabLightIcon`;
  };

  const getCurrentBorderBottomClass = (
    givenDataType: BlaceV2Type.SearchTypes,
    value: BlaceV2Type.SearchTypes,
  ) => (givenDataType === value ? styles.borderBottomBlack : styles.borderBottomDark);

  return (
    <div className={styles.container}>
      <ToggleButtonGroup
        color="secondary"
        value={[listingType]}
        onChange={handleDataTypeChange}
        aria-label="Search Buttons for Venue, Vendor"
        size="small"
        exclusive={true}
      >
        <ToggleButton
          value={BlaceV2Type.SearchTypes.Venue}
          aria-label="Venue Button"
          className={cn(
            styles.toggleButtonCommon,
            styles.textColor,
            styles.leftTab,
            getCurrentBorderBottomClass(BlaceV2Type.SearchTypes.Venue, listingType),
          )}
        >
          <BaseIcon
            iconFileName={getToggleImgSrc(BlaceV2Type.SearchTypes.Venue)}
            iconAlt="venue card icon"
            iconSize={20}
          />
          Venue
        </ToggleButton>
        <ToggleButton
          value={BlaceV2Type.SearchTypes.Vendor}
          aria-label="Vendor Button"
          className={cn(
            styles.toggleButtonCommon,
            styles.textColor,
            styles.rightTab,
            getCurrentBorderBottomClass(BlaceV2Type.SearchTypes.Vendor, listingType),
          )}
        >
          <BaseIcon
            iconFileName={getToggleImgSrc(BlaceV2Type.SearchTypes.Vendor)}
            iconAlt="vendor card icon"
            iconSize={20}
          />
          Vendor
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}

export default DataTypeButtonGroup;
