import { BlaceV2Type, VendorType } from "@/src/type";
import { RegionDisplayValues, Regions, SearchType } from "@/src/type/blaceV2";
import { StringHelper } from "@/src/util/helper";

const cities = [
  { databaseValue: Regions.LA, displayValue: RegionDisplayValues.LA },
  { databaseValue: Regions.NY, displayValue: RegionDisplayValues.NY },
];

export function parseVendorsByPivotType(
  type: string,
  vendors: Array<VendorType.Vendor> = [],
): Array<VendorType.Vendor> {
  return vendors?.filter((item: VendorType.Vendor) => item.pivot?.type === type);
}

export function getVendorCategoryFromType(type?: string): string | undefined {
  if (!type) {
    return;
  }

  //@ts-ignore
  const category = VendorType.VendorCategory?.[type];
  if (typeof category === "string") {
    return category;
  }
  return StringHelper.capitalize(StringHelper.reverseCamelCase(type));
}

export function numberToCurrenyAbbrv(num: number = 0): string {
  //will be removed after replacing to numberToCurrencyAbbrv everywhere
  let counter = 0;
  let currency = "$";
  while (counter < num - 1) {
    currency += "$";
    counter++;
  }
  return currency;
}

export function formatVendorPricing(vendor: SearchType.SearchItem): string {
  // V2 data structure
  if (typeof vendor?.price?.priceLevel !== "undefined") {
    return numberToCurrenyAbbrv(vendor?.price?.priceLevel);
  }

  // V1 data structure
  return numberToCurrenyAbbrv((vendor.data as VendorType.Vendor)?.order_price_min);
}

export function getContactName(vendor: SearchType.SearchItem): string {
  // V2 data structure
  if (Array.isArray(vendor?.contacts)) {
    const primaryContact = vendor?.contacts?.find(
      (contact) => contact.isPrimary && contact.isActive,
    );

    return primaryContact?.firstName || "";
  }

  // fallback to V1 data structure
  return getContactNameV1(vendor);
}

export function getContactNameV1(vendor: SearchType.SearchItem): string {
  const vendorContact = (vendor.data as VendorType.Vendor)?.contact_name;
 
  return vendorContact || "";
}

export function getVendorAddressForDisplay(regions?: string[]): string {
  if (!regions?.length) {
    return "";
  }
  const cities = regions.map((region) => getCityByRegion(region)).filter((city) => city);
  const uniqueCities = new Set(cities);
  return Array.from(uniqueCities)
    .join(", ")
    .replace(/,(?=[^,]+$)/, " and");
}

export function getCityByRegion(region: string): string {
  const city = cities.find((city) => city.databaseValue === region);
  return city?.displayValue || "";
}

export function getV2VendorFromV1(vendor: VendorType.Vendor) {
  return {
    title: vendor.name,
    description: vendor.description,
    slug: vendor.slug,
    dataType: BlaceV2Type.SearchTypes.Vendor,
    categories: [vendor.type],
    price: { priceLevel: vendor.order_price_min },
    images: vendor.images?.map((img) => ({
      imageHash: img.thumbs_list?.["webp-small-retina"],
      url: img.thumbs_list?.["webp-small-retina"],
    })),
  };
}
