// TODO: remove the component and its tests after March 2025
import { SearchLogic } from "@/src/model";
import FilterPanelSection from "./FilterPanelSection";

/**
 * @deprecated
 */
function FilterPanel() {
  return (
    <>
      <FilterPanelSection {...SearchLogic.FILTERS.isExclusive} />
      <FilterPanelSection {...SearchLogic.FILTERS.regions} />
      <FilterPanelSection {...SearchLogic.FILTERS.areas} />
      <FilterPanelSection {...SearchLogic.FILTERS.neighborhood} />
      <FilterPanelSection {...SearchLogic.FILTERS.capacity} />
      <FilterPanelSection {...SearchLogic.FILTERS.venueType} />
      <FilterPanelSection {...SearchLogic.FILTERS.bestUsedFor} />
      <FilterPanelSection {...SearchLogic.FILTERS.amenities} />
      <FilterPanelSection {...SearchLogic.FILTERS.vendorType} />
      <FilterPanelSection {...SearchLogic.FILTERS.vendorPricing} />
      <FilterPanelSection {...SearchLogic.FILTERS.specialFeatures} />
    </>
  );
}

export default FilterPanel;

/**
<FilterPanelSection
  dataKey="sqFootage"
  title="Square Feet"
  type="slider"
  property="dimensions/sqFootage"
  propertyType="object"
/>
<FilterPanelSection
  dataKey="guests"
  title="Guests"
  property="capacity/max"
  propertyType="object"
  type="single-choice"
  options={[
    { label: "< 80", value: "< 80" },
    { label: "80 - 150", value: "80 - 150" },
    { label: "151 - 250", value: "151 - 250" },
    { label: "251 - 400", value: "251 - 400" },
    { label: "> 400", value: "> 400" },
  ]}
/>
 */
