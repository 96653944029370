import cn from "classnames";
import { DataTypeButtonGroup, MapButton, QuickFilters } from "@/src/component/view/Listing/component";
import { BlaceV2Type, ListingDisplayVariant } from "@/src/type";
import styles from "./ListingHeader.module.scss";

interface Props {
  showMap: boolean;
  isMobileListingModalClosed: boolean;
  onMapToggle: () => void;
  viewVariant: ListingDisplayVariant;
  listingType: BlaceV2Type.SearchTypes;
}

const ListingHeader = ({
  showMap,
  isMobileListingModalClosed,
  onMapToggle,
  viewVariant,
  listingType,
 }: Props) => {
  return (
    <div className={cn(styles.listingHeader, viewVariant)}>
      <div className={cn(styles.listingHeaderInner, viewVariant)}>
        <div className={styles.quickFiltersContainer}>
          <QuickFilters listingType={listingType} />
        </div>
        <div
          className={cn(styles.headerButtonsContainer)}
        >
          <DataTypeButtonGroup isCitySelected={false} listingType={listingType} />
          {showMap && (
            <MapButton
              isCitySelected={false}
              isMobileListingModalClosed={isMobileListingModalClosed}
              handleMapToggle={onMapToggle}
              viewVariant={viewVariant}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ListingHeader;
