import { DEFAULT_LOCALE } from "@/src/const";
import { StrapiCMSType } from "@/src/type";
import { StrapiService } from "./";

export async function getDataCapture() {
  return await StrapiService.get<StrapiCMSType.DataCaptureResponse>(
    "data-captures",
    DEFAULT_LOCALE
  );
}

export async function getSitemapURLs() {
  return await StrapiService.get<StrapiCMSType.SitemapURLResponse>(
    "sitemap-urls",
    DEFAULT_LOCALE
  );
}

export async function getSimilarCarouselOverridesUsingFilter(filter: string) {
  return await StrapiService.get<StrapiCMSType.SimilarCarouselResponse>(
    "similar-carousel-overrides",
    DEFAULT_LOCALE,
    filter
  );
}

export async function getVenueMarketingAsset(filter: string) {
  return await StrapiService.get<StrapiCMSType.VenueMarketingAssetResponse>(
    "venue-marketing-assets",
    DEFAULT_LOCALE,
    filter
  );
}

export async function getBusinessFAQ() {
  return await StrapiService.get<StrapiCMSType.BusinessFAQResponse>(
    "business-faqs",
    DEFAULT_LOCALE,
    "filters[FAQs][published]=1"
  );
}

export async function getLandingPages(locale?: string, deep: boolean = true) {
  const thisLocale = !locale || locale === "undefined" ? DEFAULT_LOCALE : locale;
  return await StrapiService.get<StrapiCMSType.LandingPageType>(
      "landing-pages",
      thisLocale,
      "filters[addToSitemap]=true",
      deep
  );
}
