import { useMemo } from "react";
import { Divider, Tooltip, Typography } from "@mui/material";
import cn from "classnames";
import Image from "next/image";
import { ListingCarousel } from "@/src/component/partial/ListingCarousel";
import { KEYS } from "@/src/const";
import { SearchLogic } from "@/src/model";
import { BlaceV2Type, ComponentType } from "@/src/type";
import { NumberHelper, SharedConfigManager } from "@/src/util";
import styles from "./ListingCardV2.module.scss";

interface ListingCardInnerV2Props {
  item: BlaceV2Type.SearchType.SearchItem;
  color: string;
  location: BlaceV2Type.SearchType.SearchLocation;
  showCard: boolean;
  isMobile: boolean;
  isSmallCard?: boolean;
}

function ListingCardInnerV2({
  item,
  color,
  location,
  showCard,
  isSmallCard,
}: ListingCardInnerV2Props) {
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);
  const images: ComponentType.Carousel.ImageType[] = useMemo(
    () => SearchLogic.formatCarouselImages(item),
    [item],
  );

  const displayedLocation =
    SearchLogic.getNeighboorhoodForDisplay(location, item.regions, item.dataType) ||
    SearchLogic.getAddressForDisplay(location);

  const renderExclusiveTag = () =>
    item.facts?.isExclusive && (
      <Typography
        color={color}
        variant="subtitle2"
        component="p"
        className={cn(styles.listCardExclusive, styles.listCardImgContent)}
      >
        BLACE Exclusive
      </Typography>
    );

  return (
    <div>
      <div className={styles.listingCarousel}>
        {renderExclusiveTag()}
        <ListingCarousel
          images={images}
          load={showCard ? "eager" : "lazy"}
          imageClassName={cn(styles.listCardImgContainer, {
            [styles.isSmallCard]: isSmallCard,
          })}
          navIconClassName={cn(styles.navIcon, { [styles.isSmallCard]: isSmallCard })}
        />
      </div>

      <div>
        <div className={cn(styles.listCardDataContainer, styles.listCardDataDetails)}>
          <div
            className={cn(
              styles.listCardDataDetailsRow,
              styles.listCardExtraDetailsGroup,
              styles.listCardLocation,
            )}
          >
            <Tooltip title={displayedLocation} placement="top">
              <Typography
                color={color}
                variant="subtitle2"
                component="p"
                className={styles.listCardDataDetailsType}
              >
                {displayedLocation}
              </Typography>
            </Tooltip>
          </div>
          <div className={styles.listCardDataDetailsRow}>
            <Typography
              color={color}
              variant="h4"
              component="p"
              className={cn(styles.listCardTextLine, styles.listCardTitle)}
            >
              {item?.title}
            </Typography>
          </div>
          <Divider className={styles.listCardBreakLine} />
          <div className={styles.listCardExtraDetails}>
            <div className={styles.listCardExtraDetailsGroup}>
              {item.dataType === "venue" && (
                <Image
                  src={`${SVG_URL}/venueListingCardIconGrey.svg`}
                  height="16"
                  width="16"
                  alt="venue card icon"
                />
              )}
              {item.dataType === "vendor" && (
                <Image
                  src={`${SVG_URL}/vendorListingCardIconGrey.svg`}
                  height="16"
                  width="16"
                  alt="vendor card icon"
                />
              )}
              <Typography
                color={color}
                variant="subtitle2"
                component="p"
                className={cn(styles.listCardTextLine, styles.listCardCapitalize)}
              >
                {item.dataType}
              </Typography>
            </div>
            {(item.capacity?.max ?? 0) > 0 && (
              <div className={cn(styles.listCardExtraDetailsGroup)}>
                <Image
                  src={`${SVG_URL}/profileListingCardIconGrey.svg`}
                  height="16"
                  width="16"
                  alt="profile card icon"
                />
                <Typography
                  color={color}
                  variant="subtitle2"
                  component="p"
                  className={styles.listCardTextLine}
                >
                  {NumberHelper.withCommas(`${item.capacity?.max}`)}
                </Typography>
              </div>
            )}
            {item.dataType === "vendor" && (item.categories ?? []).length > 0 && (
              <div className={cn(styles.listCardExtraDetailsGroup)}>
                <Image
                  src={`${SVG_URL}/videoListingCardIconGrey.svg`}
                  height="16"
                  width="16"
                  alt="Vendor Category Icon"
                />
                <Typography
                  color={color}
                  variant="subtitle2"
                  component="p"
                  className={cn(styles.listCardTextLine, styles.listCardCapitalize)}
                >
                  {SearchLogic.translateCategories(item.categories)?.[0]}
                </Typography>
              </div>
            )}
            <div className={styles.listCardExtraDetailsGroup}>
              <Image
                src={`${SVG_URL}/walletListingCardIconGrey.svg`}
                height="16"
                width="16"
                alt="wallet card icon"
              />
              <Typography
                color={color}
                variant="subtitle2"
                component="p"
                className={styles.listCardTextLine}
                sx={{
                  paddingLeft: "2px",
                }}
              >
                {SearchLogic.formatSearchPricing(item)}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListingCardInnerV2;
