import { createContext } from "react";
import { DEFAULT_LOCALE } from "@/src/const";
import { StrapiCMSType } from "@/src/type";

export interface CMSContextType {
  globalWebConfig: StrapiCMSType.GlobalWebConfigResponse | undefined;
  locale: string;
  constructLink: (path: string) => string;
}

export const CMSContext = createContext<CMSContextType>({
  globalWebConfig: undefined,
  locale: DEFAULT_LOCALE,
  constructLink: () => {
    return "";
  },
});
