import { ReactElement } from "react";
import { BlaceV2Type, ListingDisplayVariant } from "@/src/type";

export enum VenueQuickFilterValues {
  CAPACITY = "capacity",
  BEST_USED_FOR = "bestUsedFor",
  REGIONS = "regions",
}

export enum ListingCardType {
  SIMILAR = "similar",
  POPULAR = "popular",
}

export enum VendorQuickFilterValues {
  PRICING = "vendorPricing",
  TYPE = "vendorType",
  REGIONS = "regions",
}

export enum MobileListingModalState {
  CLOSED = "closed",
  DEFAULT = "default",
  OPEN = "open"
}

export type LastSearchQuery = {
    cards: ReactElement[];
    geoPoints: BlaceV2Type.AzureSearchQueryType.Response<BlaceV2Type.SearchType.SearchItem>;
    pageNumber: number;
    query: BlaceV2Type.AzureSearchQueryType.Request;
    resultsPerPage: number;
    searchId?: string;
    searchResults: BlaceV2Type.AzureSearchQueryType.Response<BlaceV2Type.SearchType.SearchItem>;
    searchTerm?: string;
    viewVariant: keyof typeof ListingDisplayVariant;
}

export type VenueWithLinkedVendors = {
    exclusive: BlaceV2Type.SearchType.Search[];
    preferred: BlaceV2Type.SearchType.Search[];
};
