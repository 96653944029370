import { useEffect, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import cn from "classnames";
import {
  BaseButton,
  BaseButtonStyles,
  ImgBgResponsive,
} from "@/src/component/base";
import { CarouselImage } from "@/src/component/partial";
import { ASSET_URL } from "@/src/const";
import { ComponentType, ListingDisplayVariant } from "@/src/type";
import { ImageFromCDN } from "@/src/util";
import styles from "./AdvertisingCard.module.scss";

interface AdvertisingCardProps {
  id: string;
  placement: string;
  viewVariant: keyof typeof ListingDisplayVariant;
}

//data needed for CMS
//backgroundColor
//title
//description[]
//packageDetails[]
//imageUrl
//title
//category
//ctaLink
//ctaText
//placement

function AdvertisingCard({ id, placement, viewVariant }: AdvertisingCardProps) {
  const [showCarouselAfterLoad, setShowCarouselAfterLoad] =
    useState<boolean>(false);

  const images: ComponentType.Carousel.ImageType[] = useMemo(() => {
    return [
      {
        url: `${ASSET_URL}/blacePackages1.jpeg`,
        id: "AdImage1",
        alt: "All Inclusive Package Image",
      },
      {
        url: `${ASSET_URL}/blacePackages2.jpeg`,
        id: "AdImage1",
        alt: "Catering Package Image",
      },
      {
        url: `${ASSET_URL}/blacePackages3.jpeg`,
        id: "AdImage1",
        alt: "Conference Package Image",
      },
      {
        url: `${ASSET_URL}/blacePackages4.jpeg`,
        id: "AdImage1",
        alt: "Experiental Package Image",
      },
    ];
  }, []);

  /**
   * show carousel after load if more than 1 image
   * carousel has alot of dom, so better to load after page is rendered in listing view
   */
  useEffect(() => {
    if ((images ?? []).length < 2) {
      return;
    }

    function handleListener() {
      setShowCarouselAfterLoad(true);
      window.removeEventListener("scroll", handleListener);
    }

    window.addEventListener("scroll", handleListener, { passive: true });
    return () => window.removeEventListener("scroll", handleListener);
  }, [images]);

  return (
    <div id={`${id}.${placement}`} className={styles.adCard}>
      <div className={cn(styles.adCardInner, viewVariant)}>
        <div className={cn(styles.adCardInnerImage, viewVariant)}>
          {showCarouselAfterLoad && viewVariant === ListingDisplayVariant.full ? (
            <CarouselImage
              images={images}
              autoPlay={true}
              imageClassName={styles.adImage}
              navButtonsAlwaysVisible={false}
              quality={90}
              width={400}
              fixedImageSize={true}
            />
          ) : (
            <ImgBgResponsive
              imageUrl={ImageFromCDN.imageSizeAndQuality(
                images?.[0].url,
                90,
                400
              )}
              lazy={"eager"}
            />
          )}
        </div>
        <div className={cn(styles.adCardInnerAbout, viewVariant)}>
          <div className={styles.adCardInnerAboutDetails}>
            <Typography variant="h2" component="p" color="white">
              Produce your next event with ease!
            </Typography>
            <Typography variant="subtitle1" component="p" fontWeight="400" color="white">
              Use our all-inclusive packages for seamless booking of all
              vendors, with the support of the BLACE team.
            </Typography>
            <Typography variant="subtitle1" component="p" fontWeight="400" color="white">
              BLACE packages are curated combinations of our favorite venues and
              vendors, from catering to entertainment.
            </Typography>
          </div>
          <a href="/packages" className={styles.adLink}>
            <BaseButton
              variant="outlined"
              sx={BaseButtonStyles.white}
              id={`AdvertisingCard.packages.${placement}`}
              aria-label="Go to Packages"
            >
              Get started
            </BaseButton>
          </a>
        </div>
      </div>
    </div>
  );
}

export default AdvertisingCard;
