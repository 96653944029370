import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Link from "next/link";
import { BlaceLogoSmallSVG } from "@/src/asset";
import { useCMS } from "@/src/component/provider";
import { useBreakPointDown } from "@/src/hook";
import { AuthLogic } from "@/src/model";
import { AuthType, StrapiCMSType } from "@/src/type";
import styles from "@/src/component/partial/Header/Header.module.scss";
import { Account } from "./Account";
import { BusinessDashboard } from "./BusinessDashboard";
import { EvenMore } from "./EvenMore";
import { Events } from "./Events";
import { Profile } from "./Profile";
import { Question } from "./Question";

export interface NavigationDrawerProps {
  headerLinks?: Array<StrapiCMSType.Base.Link>;
  onClose?: () => void;
}
export interface CurrentUserProps {
  currentUser?: AuthType.LoginResponseProfile;
}

export function NavigationDrawer({ headerLinks, onClose }: NavigationDrawerProps) {
  const cms = useCMS();
  const [currentUserData, setCurrentUserData] = useState<AuthType.LoginResponseProfile | undefined>(
    undefined,
  );
  const isMobile = useBreakPointDown("md");

  useEffect(() => {
    const currentUser = AuthLogic.getAuthCurrentUser();
    setCurrentUserData(currentUser);
  }, []);

  return (
    <div className={styles.drawerWrapper}>
      {isMobile && (
        <div className={styles.drawerHeader}>
          <Link onClick={onClose} href={cms.constructLink("/discover")} aria-label="Go to Search">
            <div className={styles.headerNavMobileLogo}>
              <BlaceLogoSmallSVG />
            </div>
          </Link>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon className={styles.closeDrawerIcon} />
          </IconButton>
        </div>
      )}
      <Profile currentUser={currentUserData} />
      <Question />
      {/**TODO: need to address this with a common function to detect business user vs client user */}
      {currentUserData?.role !== "vendor" &&
        currentUserData?.role !== "landlord" &&
        currentUserData?.role !== "blace-sales" && <Events currentUser={currentUserData} />}
      {(currentUserData?.role == "vendor" ||
        currentUserData?.role == "landlord" ||
        currentUserData?.role == "blace-sales") && (
        <BusinessDashboard currentUser={currentUserData} />
      )}
      <Account currentUser={currentUserData} setCurrentUserData={setCurrentUserData} />
      <EvenMore headerLinks={headerLinks} />
    </div>
  );
}
