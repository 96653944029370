import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { BaseIcon } from "@/src/component/base";
import { ActivityLogic } from "@/src/model";
import { ListingDisplayVariant } from "@/src/type";
import styles from "./MapButton.module.scss";

interface MapButtonProps {
  handleMapToggle: (viewVariant?: keyof typeof ListingDisplayVariant) => void;
  isCitySelected: boolean;
  isMobileListingModalClosed: boolean;
  viewVariant: ListingDisplayVariant;
}

function MapButton({
  // isCitySelected,
  isMobileListingModalClosed,
  handleMapToggle,
  viewVariant,
}: MapButtonProps) {
  const handleFormat = () => {
    ActivityLogic.toActivityService({
      action: "changeMapView",
      actionMessage: viewVariant === ListingDisplayVariant.map ? "closed" : "open",
      locationInApp: "MapButton.ts",
    });

    handleMapToggle();
  };

  return (
    <div className={styles.container}>
      <ToggleButtonGroup
        color="secondary"
        value={
          viewVariant === ListingDisplayVariant.map || isMobileListingModalClosed
            ? [ListingDisplayVariant.map]
            : []
        }
        onChange={handleFormat}
        aria-label="Toggle Map On / Off"
        size="small"
        className={styles.toggleButtonGroup}
      >
        <ToggleButton value="map" aria-label="Map Button" className={styles.toggleButtonCommon}>
          <BaseIcon
            iconFileName={
              viewVariant === ListingDisplayVariant.full ? "mapSheetLightIcon" : "viewHideLightIcon"
            }
            iconAlt={viewVariant === ListingDisplayVariant.full ? "show map" : "hide map"}
            iconSize={20}
          />
          {viewVariant === ListingDisplayVariant.full && !isMobileListingModalClosed
            ? "Show Map"
            : "Hide Map"}
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}

export default MapButton;
