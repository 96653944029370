import { useRef } from "react";
import { Typography } from "@mui/material";
import cn from "classnames";
import { ListingCardV2 } from "@/src/component/partial";
import { BlaceV2Type } from "@/src/type";
import { ListingCardType } from "@/src/type/component/Listing";
import styles from "./PopularListings.module.scss";

interface PopularListingsProps {
  listings: BlaceV2Type.SearchType.Search[];
  listingCategory: BlaceV2Type.SearchTypes;
}

const PopularListings = ({ listings, listingCategory }: PopularListingsProps) => {
  const popularListingsContainerRef = useRef<HTMLDivElement>(null);
  const title =
    listingCategory === BlaceV2Type.SearchTypes.Venue
      ? "Popular venues in your area"
      : "Popular vendors in your area";

  if (!listings.length) {
    return false;
  }

  return (
    <div
      ref={popularListingsContainerRef}
      className={cn(styles.popularListingsContainer, listingCategory)}
    >
      <Typography className={styles.popularListingsTitle}>{title}</Typography>
      <div className={styles.popularListingsListWrapper}>
        <div className={cn(styles.popularListingsList, listingCategory)}>
          {listings.map((listing) => (
            <ListingCardV2
              key={listing.searchId || listing.slug}
              item={listing}
              eager={false}
              type={ListingCardType.POPULAR}
              isSmallCard
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PopularListings;
