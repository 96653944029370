import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { useEffect, useState } from "react";
import { Mousewheel, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ListingCardV2 } from "@/src/component/partial/ListingCardV2";
import { BlaceV2Type } from "@/src/type";
import SimilarCarouselButtons from "./SimilarCarouselButtons";

export interface SearchSimilarSearchProps {
  dataType: BlaceV2Type.SearchType.SearchDataType;
  region: string;
  category?: string[];
  neighboorhood?: string;
  maxCapacity?: number;
}
export interface SimilarCarouselSearchProps {
  search?: SearchSimilarSearchProps;
}

export type SearchResult = BlaceV2Type.SearchType.SearchItem[];
interface SimilarCarouselProps extends SimilarCarouselSearchProps {
  classes?: {
    container?: string;
    swiper?: string;
    buttonsContainer?: string;
    swiperEmptySlot?: string;
  };
  slidesPerView: number;
  sideButtons?: boolean;
  cards: BlaceV2Type.SearchType.SearchItem[];
  swiperId?: string | number;
}


function SimilarCarousel({
  classes,
  slidesPerView,
  sideButtons,
  cards,
  swiperId
}: SimilarCarouselProps) {
  const [searchResults, setSearchResults] = useState<SearchResult | undefined>(
    undefined
  );
  const [beginOrEnd, setBeginOrEnd] = useState<"begin" | "end" | undefined>(
    undefined
  );

  /**
 * Swiper uses class name to add required onClick for prev/next functionality
 * Set the max number of results (todo: move this to CMS global web config)
 */
  const PREV_BUTTON = `similar-carousel-swiper-prev-${swiperId}`;
  const NEXT_BUTTON = `similar-carousel-swiper-next-${swiperId}`;

  /**
   * run a new search base on a search term
   */
  useEffect(() => {
    if (cards) {
      setSearchResults(cards);
    }
  }, [cards]);

  if (!searchResults) {
    return <></>;
  }

  return (
    <div>
      <SimilarCarouselButtons
        sideButtons={sideButtons}
        prevClassName={PREV_BUTTON}
        nextClassName={NEXT_BUTTON}
        beginOrEnd={beginOrEnd}
        className={classes?.buttonsContainer}
      />
      <div className={classes?.container}>
        <Swiper
          className={classes?.swiper}
          modules={[Navigation, Mousewheel]}
          spaceBetween={24}
          slidesPerView={slidesPerView}
          onSlideChange={() => {
            if (beginOrEnd) {
              setBeginOrEnd(undefined);
            }
          }}
          mousewheel={true}
          onReachBeginning={() => setBeginOrEnd("begin")}
          onReachEnd={() => setBeginOrEnd("end")}
          navigation={{
            nextEl: `.${NEXT_BUTTON}`,
            prevEl: `.${PREV_BUTTON}`,
          }}
        >
          {(searchResults ?? []).map((item, i) => (
            <SwiperSlide key={i}>
              <ListingCardV2 item={item} eager={false} />
            </SwiperSlide>
          ))}
          {classes?.swiperEmptySlot && (
            <SwiperSlide>
              <div className={classes.swiperEmptySlot} />
            </SwiperSlide>
          )}
        </Swiper>
      </div>
    </div>
  );
}

export default SimilarCarousel;
