import { APP_TENANT, BLACE_V2_API, KEYS } from "@/src/const";
import { BlaceV2Type, VendorType } from "@/src/type";
import { SearchType } from "@/src/type/blaceV2";
import { SharedConfigManager } from "@/src/util";
import { HTTP, HTTPResponse } from "./HTTP";

export async function getSlug(
  slug: string,
): Promise<HTTPResponse<BlaceV2Type.BlaceV2Response<BlaceV2Type.SearchType.Search>>> {
  const blaceV2Url = SharedConfigManager.getValue(KEYS.BLACE_V2_API) ?? BLACE_V2_API;
  const action = new HTTP<BlaceV2Type.BlaceV2Response<BlaceV2Type.SearchType.Search>>(
    `${blaceV2Url}/search/slug/${slug}`,
    HTTP.methods.GET,
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}

export async function getAllSlugs(): Promise<HTTPResponse<BlaceV2Type.BlaceV2Response<string[]>>> {
  const blaceV2Url = SharedConfigManager.getValue(KEYS.BLACE_V2_API) ?? BLACE_V2_API;
  const action = new HTTP<BlaceV2Type.BlaceV2Response<string[]>>(
    `${blaceV2Url}/search/slugs/all`,
    HTTP.methods.GET,
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}

export async function getSlugWithV1PayloadForVenue(
  slug: string,
): Promise<
  HTTPResponse<BlaceV2Type.BlaceV2Response<SearchType.SearchWithV1Payload<SearchType.Search>>>
> {
  const blaceV2Url = SharedConfigManager.getValue(KEYS.BLACE_V2_API) ?? BLACE_V2_API;
  const action = new HTTP<
    BlaceV2Type.BlaceV2Response<SearchType.SearchWithV1Payload<SearchType.Search>>
  >(`${blaceV2Url}/search/slug/${slug}/type/venue`, HTTP.methods.GET);
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}

export async function getSlugWithV1PayloadForVendor(
  slug: string,
): Promise<
  HTTPResponse<BlaceV2Type.BlaceV2Response<SearchType.SearchWithV1Payload<VendorType.Vendor>>>
> {
  const blaceV2Url = SharedConfigManager.getValue(KEYS.BLACE_V2_API) ?? BLACE_V2_API;
  const action = new HTTP<
    BlaceV2Type.BlaceV2Response<SearchType.SearchWithV1Payload<VendorType.Vendor>>
  >(`${blaceV2Url}/search/slug/${slug}/type/vendor`, HTTP.methods.GET);
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}

export async function postSearchQuery(
  request: BlaceV2Type.AzureSearchQueryType.Request,
): Promise<HTTPResponse<BlaceV2Type.SearchType.SearchResponse>> {
  const blaceV2Url = SharedConfigManager.getValue(KEYS.BLACE_V2_API) ?? BLACE_V2_API;
  const action = new HTTP<BlaceV2Type.SearchType.SearchResponse>(
    `${blaceV2Url}/search/query`,
    HTTP.methods.POST,
    request,
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}

export async function postSearchAutoComplete(
  request: BlaceV2Type.AzureSearchAutoCompleteType.Request,
): Promise<HTTPResponse<BlaceV2Type.AzureSearchAutoCompleteType.SearchResponse>> {
  const blaceV2Url = SharedConfigManager.getValue(KEYS.BLACE_V2_API) ?? BLACE_V2_API;
  const action = new HTTP<BlaceV2Type.AzureSearchAutoCompleteType.SearchResponse>(
    `${blaceV2Url}/search/autocomplete`,
    HTTP.methods.POST,
    request,
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}

export async function postSearchSuggestion(
  request: BlaceV2Type.AzureSearchSuggestionType.Request,
): Promise<HTTPResponse<BlaceV2Type.AzureSearchSuggestionType.SearchResponse>> {
  const blaceV2Url = SharedConfigManager.getValue(KEYS.BLACE_V2_API) ?? BLACE_V2_API;
  const action = new HTTP<BlaceV2Type.AzureSearchSuggestionType.SearchResponse>(
    `${blaceV2Url}/search/suggestion`,
    HTTP.methods.POST,
    request,
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}
