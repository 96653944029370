import React, { useEffect, useState } from "react";
import TextsmsTwoToneIcon from "@mui/icons-material/TextsmsOutlined";
import { Box, Typography } from "@mui/material";
import cn from "classnames";
import Link from "next/link";
import { BlaceLogoSVG, BurgerSVG, RightArrowSVG } from "@/src/asset";
import { BaseIcon, DrawerFixedToHeader } from "@/src/component/base";
import { PageLayoutContext } from "@/src/component/layout/PageLayout";
import { GlobalSearch } from "@/src/component/partial";
import { NavigationDrawer } from "@/src/component/partial/Header/component/NavigationDrawer";
import { useApp, useCMS } from "@/src/component/provider";
import { QuickFilters } from "@/src/component/view/Listing/component";
import { MobileQuickFiltersInfo } from "@/src/component/view/Listing/component/MobileQuickFiltersInfo";
import { useBreakPointDown } from "@/src/hook";
import { SearchLogic } from "@/src/model";
import styles from "./Header.module.scss";

interface HeaderProps {
  transparentBeforeScroll?: boolean;
}

function Header({ transparentBeforeScroll }: HeaderProps) {
  const cms = useCMS();
  const app = useApp();
  const currentListingsType = SearchLogic.getSearchDataTypeFromApp(app);

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [isListingQuickFiltersOpen, setIsListingQuickFiltersOpen] = useState(false);
  const [transparentHeader, setTransparentHeader] = useState<boolean>(!!transparentBeforeScroll);
  const [globalSearchValue, setGlobalSearchValue] = useState<string>("");

  const headerLinks = cms.globalWebConfig?.data?.attributes?.header?.links;
  const isMobile = useBreakPointDown("md");
  const { showListingQuickFiltersInHeader } = PageLayoutContext.usePageLayoutContext();
  const showListingQuickFiltersInfo =
    isMobile && showListingQuickFiltersInHeader && !isListingQuickFiltersOpen;

  const appliedExtraFiltersCount = +SearchLogic.countAppliedExtraFilters(
    currentListingsType,
    app.searchFilters?.getFilterData,
  );

  const appliedQuickFiltersCount = +SearchLogic.countAppliedQuickFilters(
    currentListingsType,
    app.searchFilters?.getFilterData,
  );

  const handleOpenQuickFilters = () => {
    setIsListingQuickFiltersOpen(true);
  };

  const handleCloseQuickFilters = () => {
    setIsListingQuickFiltersOpen(false);
  };

  /**
   * do not run animation when screen not in small screen
   *
   * @param {boolean} val - whether the search input is focused
   * @returns
   */
  function handleSearchIsFocused(val: boolean) {
    if (!isMobile) {
      //handle resposnive screen size increasing while drawer is open
      if (app.searchFocus && !val) {
        app.setSearchFocus(false);
      }
      return;
    }
    app.setSearchFocus(val);
  }

  useEffect(() => {
    if (!app.searchFocus) {
      setIsListingQuickFiltersOpen(false);
    }
  }, [app.searchFocus]);

  /**
   * turn off side nav if search is engage
   */

  useEffect(() => {
    if (drawerOpen && app.searchFocus) {
      setDrawerOpen(false);
    }
  }, [app.searchFocus, drawerOpen]);

  useEffect(() => {
    if (
      showListingQuickFiltersInHeader &&
      appliedExtraFiltersCount === 0 &&
      appliedQuickFiltersCount === 0 &&
      !globalSearchValue
    ) {
      setIsListingQuickFiltersOpen((prev) => !prev && true);
    } else {
      setIsListingQuickFiltersOpen((prev) => prev && false);
    }
    //do check if we should open quick filters only when user come on Listing page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showListingQuickFiltersInHeader]);

  /**
   * when transparent header turn background on when scroll > 0
   * TODO remove after new filters applied
   */
  useEffect(() => {
    // for now without header background
    return;
    if (
      typeof app?.searchFilters?.filterState?.regions !== "string" ||
      !app?.searchFilters?.filterState?.regions
    ) {
      setTransparentHeader(false);
      return;
    }

    function handleScroll() {
      if (window.scrollY > 0) {
        setTransparentHeader(false);
      } else {
        setTransparentHeader(true);
      }
    }

    setTransparentHeader(true);
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [app?.searchFilters?.filterState?.regions]);

  return (
    <>
      <header
        className={cn(styles.headerSticky, styles.header, {
          [styles.listingQuickFiltersOpen]: isListingQuickFiltersOpen,
          [styles.headerNavTransparent]: transparentHeader && false,
        })}
        data-testid="header"
      >
        <div className={styles.headerNavContainer}>
          <div className={cn(styles.headerNav, app.searchFocus ? "focused" : "")}>
            {isMobile ? (
              <div
                className={styles.openNavbarDrawerBtn}
                onClick={() => setDrawerOpen(true)}
                data-testid="navbar-drawer"
              >
                <div className={styles.openNavbarDrawerBtnInner}>
                  <BurgerSVG width={18} />
                </div>
              </div>
            ) : (
              <Link href={cms.constructLink("/discover")} aria-label="Go to Search">
                <div className={styles.headerNavDesktopLogo}>
                  <BlaceLogoSVG />
                </div>
              </Link>
            )}

            <div className={styles.headerSearchInput}>
              {showListingQuickFiltersInfo && !globalSearchValue ? (
                <MobileQuickFiltersInfo onClick={handleOpenQuickFilters} listingType={currentListingsType} />
              ) : (
                <GlobalSearch
                  setIsFocused={handleSearchIsFocused}
                  openListingQuickFilters={() => setIsListingQuickFiltersOpen(true)}
                  isListingQuickFiltersOpen={isListingQuickFiltersOpen}
                  searchValue={globalSearchValue}
                  setSearchValue={setGlobalSearchValue}
                />
              )}
            </div>

            <div className={styles.contactMailWrapper}>
              <Link href={cms.constructLink("/inquiry/general")} className={styles.linkButton}>
                <div className={styles.contactMailContent}>
                  <div className={styles.contactMailIconWrapper}>
                    <TextsmsTwoToneIcon color="inherit" />
                  </div>
                  <div className={styles.contactMailTextWrapper}>
                    <Typography
                      variant="caption"
                      component="p"
                      className={cn(styles.contactQuestionText, {
                        [styles.contactQuestionTextWhite]: transparentHeader && false,
                      })}
                    >
                      Have a question?
                    </Typography>
                    <Typography
                      variant="caption"
                      component="p"
                      className={styles.contactSendMailText}
                    >
                      Contact a BLACE expert
                    </Typography>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          {!isMobile ? (
            <div
              className={styles.openNavbarDrawerBtn}
              onClick={() => setDrawerOpen(!drawerOpen)}
              data-testid="navbar-drawer"
            >
              <div className={styles.openNavbarDrawerBtnInner}>
                {drawerOpen ? <RightArrowSVG width={9} /> : <BurgerSVG width={18} />}
              </div>
            </div>
          ) : (
            showListingQuickFiltersInHeader && (
              <Box
                className={styles.extraFiltersWrapper}
                onClick={() => {
                  app.setShowExtraFilters(true);
                  app.setSearchFocus(true);
                }}
              >
                <BaseIcon iconFileName="filterWhiteIcon" iconAlt="extra filters" iconSize={20} />
                {appliedExtraFiltersCount > 0 && (
                  <span className={styles.filtersCount}>{appliedExtraFiltersCount}</span>
                )}
              </Box>
            )
          )}
        </div>
        <div
          className={cn(styles.listingQuickFiltersContainer, {
            [styles.visible]: isListingQuickFiltersOpen,
          })}
        >
          <QuickFilters onSearchClick={handleCloseQuickFilters} listingType={currentListingsType} />
        </div>
      </header>
      <DrawerFixedToHeader
        anchor={isMobile ? "left" : "right"}
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        className={styles.drawerScrollbar}
        classes={{ paper: styles.drawerPaper }}
      >
        <NavigationDrawer headerLinks={headerLinks} onClose={() => setDrawerOpen(false)} />
      </DrawerFixedToHeader>
    </>
  );
}

export default Header;
