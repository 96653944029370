import React, { useEffect, useMemo, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse, Typography } from "@mui/material";
import cn from "classnames";
import { FilterTagLabel } from "@/src/component/base/FilterTagLabel";
import styles from "./TagSection.module.scss";

type Tag = {
  label: string;
  value: string;
  [key: string]: any;
};

interface TagSectionProps {
  sectionTitle: string;
  selectedTagsCount?: number;
  tags: Tag[];
  isExpandable?: boolean;
  showMoreButton?: boolean;
  startExpanded?: boolean;
  startShowAll?: boolean;
  maxVisibleTags?: number;
  sectionHeaderStyles?: string;
  tagSectionStyles?: string;
  sectionTitleStyles?: string;
  checkIsSelectedTag: (tag: Tag) => boolean;
  handleTagClick: (tag: Tag, isSelected: boolean) => void;
}

function TagSection({
  sectionTitle,
  selectedTagsCount,
  tags,
  isExpandable = false,
  showMoreButton = false,
  startExpanded = true,
  startShowAll = true,
  maxVisibleTags,
  sectionHeaderStyles,
  tagSectionStyles,
  sectionTitleStyles,
  checkIsSelectedTag,
  handleTagClick,
}: TagSectionProps) {
  const [isOpen, setIsOpen] = useState<boolean>(startExpanded);
  const [showAll, setShowAll] = useState<boolean>(startShowAll);

  const visibleTags = useMemo(
    () => (showAll ? tags : tags.slice(0, maxVisibleTags)),
    [showAll, tags, maxVisibleTags],
  );

  const renderToggleIcon = () =>
    isOpen ? (
      <ExpandLessIcon className={styles.expandIcon} data-testid="expand-less-icon" />
    ) : (
      <ExpandMoreIcon className={styles.expandIcon} data-testid="expand-more-icon" />
    );

  const toggleExpand = () => setIsOpen((prevOpen: boolean) => !prevOpen);
  const toggleShowAll = () => setShowAll((prev: boolean) => !prev);

  useEffect(() => {
    const hasSelectedTags: boolean = tags.some((tag) => checkIsSelectedTag(tag));
    if (hasSelectedTags) {
      setIsOpen(true);
    }
    const tagsNumber = tags.length;
    const hasSelectedTagsAfterShowMore = tags.some(
      (tag, index) => index >= (maxVisibleTags ?? tagsNumber) && checkIsSelectedTag(tag),
    );
    if (hasSelectedTagsAfterShowMore) {
      setShowAll(true);
    }
  }, [tags, checkIsSelectedTag, setIsOpen, setShowAll]);

  return (
    <div className={cn(styles.tagSection, tagSectionStyles)}>
      <div className={cn(styles.sectionHeader, sectionHeaderStyles)}>
        <h3 className={cn(styles.sectionTitle, sectionTitleStyles)}>{sectionTitle}</h3>
        {!!selectedTagsCount && (
          <span className={styles.selectedTagsCount}>
            {selectedTagsCount} tag{selectedTagsCount === 1 ? "" : "s"}
          </span>
        )}
        {isExpandable && (
          <button
            onClick={toggleExpand}
            className={styles.expandIconWrapper}
            aria-label={`${isOpen ? "Collapse" : "Expand"} ${sectionTitle}`}
            aria-expanded={isOpen}
            data-testid="expand-btn"
          >
            {renderToggleIcon()}
          </button>
        )}
      </div>
      <Collapse in={isOpen}>
        <div className={styles.tagList}>
          {visibleTags.map((tag) => (
            <FilterTagLabel
              key={tag.value}
              tag={tag}
              isSelected={checkIsSelectedTag(tag)}
              onClick={handleTagClick}
            />
          ))}
          {showMoreButton && (
            <Typography
              component="button"
              className={styles.showMoreBtn}
              onClick={toggleShowAll}
              data-testid="show-more-btn"
              aria-label={`${showAll ? "Show less" : "Show more"} ${sectionTitle}`}
            >
              {showAll ? "Show less" : "Show more"}
            </Typography>
          )}
        </div>
      </Collapse>
    </div>
  );
}

export default TagSection;
