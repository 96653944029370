import { SearchLogic } from "@/src/model";
import { BlaceV2API } from "@/src/service";
import { BlaceV2Type, ListingDisplayVariant } from "@/src/type";
import { LastSearchQuery } from "@/src/type/component/Listing";

export const getPopularListings = async (
  listingType: BlaceV2Type.SearchTypes,
  itemsCount: number,
  userRegion?: string,
  sessionId?: string
) => {
  const query = SearchLogic.defaultQuery(itemsCount);

  query.sessionId = sessionId || query.sessionId;
  query.queryType = ListingDisplayVariant.full;
  query.filter = SearchLogic.constructFilter({
    dataType: [listingType],
    regions: userRegion,
  });

  const searchResponse = await BlaceV2API.SearchServiceV2.postSearchQuery(query);
  
  return searchResponse?.body?.payload?.value || [];
};

export const getListingsCountTitle = (lastSearchQuery: LastSearchQuery
) => {
  const totalVenues = lastSearchQuery.searchResults["@odata.count"] || 0;
  const currentPageVenues = lastSearchQuery.cards?.length;

  if (totalVenues > currentPageVenues) {
    return `Over ${currentPageVenues} venues`;
  }

  return `${currentPageVenues} venues`;
};
