import { ReactElement } from "react";
import { ListingGrid } from "@/src/component/partial";
import { DraggableListingView, NoResults, PaginationSection } from "@/src/component/view/Listing/component";
import { ListingDisplayVariant } from "@/src/type";
import { SearchTypes } from "@/src/type/blaceV2";
import { MobileListingModalState } from "@/src/type/component/Listing";
import styles from "./SlideUpListingsPanel.module.scss";

interface Props {
  listings: ReactElement[];
  pages: number;
  currentPage: number;
  viewVariant: ListingDisplayVariant;
  modalState: MobileListingModalState;
  onResetFilters: () => void;
  listingType: SearchTypes;
  onToggleModalState: (state: MobileListingModalState) => void;
  title: string;
}

const SlideUpListingsPanel = ({
  listings,
  pages,
  currentPage,
  modalState,
  viewVariant,
  onResetFilters,
  listingType,
  onToggleModalState,
  title
}: Props) => {
  return (
    <DraggableListingView
      state={modalState}
      onModalDragToTop={() => onToggleModalState(MobileListingModalState.OPEN)}
      onModalDragToBottom={() => onToggleModalState(MobileListingModalState.CLOSED)}
    >
      <div className={styles.listingCount}>{title}</div>
      {listings.length ?
        <ListingGrid variant={ListingDisplayVariant.map} cards={listings} />
        :
        <NoResults onReset={onResetFilters} listingType={listingType} />
      }
      {pages > 1 && (
        <PaginationSection
          currentPage={currentPage}
          pages={pages}
          isPositionFixed={viewVariant === ListingDisplayVariant.map}
        />
      )}
    </DraggableListingView>
  );
};

export default SlideUpListingsPanel;
