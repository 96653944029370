import cn from "classnames";
import { BaseButton, ImgFromCDN } from "@/src/component/base";
import { useBreakPointDown } from "@/src/hook";
import { BlaceV2Type } from "@/src/type";
import { ImageFromCDN } from "@/src/util";
import styles from "./NoResults.module.scss";

const venuesImg = "NoVenuesBanner.png";
const vendorsImg = "NoVendorsBanner.png";

interface NoResultsProps {
  onReset: () => void;
  listingType: BlaceV2Type.SearchTypes;
}

const NoResults = ({ onReset, listingType }: NoResultsProps) => {
  const screenSize = useBreakPointDown("md") ? "mobile" : "desktop";
  const imageSize = {
    [BlaceV2Type.SearchTypes.Venue]: {
      mobile: {
        width: 61,
        height: 77,
      },
      desktop: {
        width: 140,
        height: 177,
      },
    },
    [BlaceV2Type.SearchTypes.Vendor]: {
      mobile: {
        width: 80,
        height: 55,
      },
      desktop: {
        width: 200,
        height: 138,
      },
    },
  };
  const title =
    listingType === BlaceV2Type.SearchTypes.Venue
      ? "Oops... no venues match this exact search"
      : "Oops... no vendors match this exact search";
  const imgUrl = ImageFromCDN.imageSizeAndQuality(
    listingType === BlaceV2Type.SearchTypes.Venue ? venuesImg : vendorsImg,
    90,
    listingType === BlaceV2Type.SearchTypes.Venue ? 140 : 200,
    true,
  );

  return (
    <div className={cn(styles.noResults)} data-testid="no-listings-result">
      <ImgFromCDN
        src={imgUrl}
        alt="No results"
        width={imageSize[listingType][screenSize].width}
        height={imageSize[listingType][screenSize].height}
        className={styles.businessImage}
        data-testid="no-results-img"
      />
      <p className={cn(styles.noResultsTitle)}>{title}</p>
      <BaseButton
        className={cn(styles.resetFiltersButton)}
        onClick={onReset}
        data-testid="reset-btn"
      >
        Reset filters
      </BaseButton>
    </div>
  );
};

export default NoResults;
