import { ReactElement, useEffect, useRef } from "react";
import cn from "classnames";
import { ListingGrid } from "@/src/component/partial";
import {
  NoResults,
  PaginationSection,
  PopularListings,
} from "@/src/component/view/Listing/component";
import { BlaceV2Type, ListingDisplayVariant } from "@/src/type";
import styles from "./ListingsList.module.scss";

interface Props {
  listings: ReactElement[];
  popularListings: BlaceV2Type.SearchType.Search[];
  onFiltersReset: () => void;
  listingType: BlaceV2Type.SearchTypes;
  pages: number;
  currentPage: number;
  viewVariant: ListingDisplayVariant;
}

const ListingsList = ({
  listings,
  popularListings,
  onFiltersReset,
  listingType,
  pages,
  currentPage,
  viewVariant,
}: Props) => {
  const listRef = useRef<HTMLDivElement | null>(null);

  const scrollToRef = () => {
    listRef.current?.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToRef();
  }, [currentPage]);

  return (
    <div className={cn(styles.listingGrid, viewVariant)} ref={listRef}>
      {listings.length ? (
        <ListingGrid variant={viewVariant} cards={listings} />
      ) : (
        <div className={cn(styles.noResultsWrapper, viewVariant)}>
          <NoResults onReset={onFiltersReset} listingType={listingType} />
          <PopularListings
            listings={popularListings}
            listingCategory={listingType ?? BlaceV2Type.SearchTypes.Venue}
          />
        </div>
      )}
      {pages > 1 && (
        <PaginationSection currentPage={currentPage} pages={pages} isPositionFixed={false} />
      )}
    </div>
  );
};

export default ListingsList;
