import React, { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import cn from "classnames";
import { useApp } from "@/src/component/provider";
import { SearchLogic } from "@/src/model";
import { BlaceV2Type } from "@/src/type";
import { VendorQuickFilterValues, VenueQuickFilterValues } from "@/src/type/component/Listing";
import styles from "./MobileQuickFiltersInfo.module.scss";

interface MobileQuickFiltersInfoProps {
  onClick: () => void;
  listingType: BlaceV2Type.SearchTypes;
}

function getSecondRowText(isVenue: boolean, guestCount?: string, pricing?: string): string {
  if (isVenue) {
    if (guestCount) {
      const pluralSuffix = guestCount === "1" ? "" : "s";
      return `${guestCount} guest${pluralSuffix}`;
    }
    return "Any guests";
  }

  return pricing || "Any pricing";
}

function MobileQuickFiltersInfo({ onClick, listingType }: MobileQuickFiltersInfoProps) {
  const app = useApp();
  const isVenue = listingType !== BlaceV2Type.SearchTypes.Vendor;
  const eventTypeOptions = useMemo(() => SearchLogic.FILTERS.bestUsedFor.options || [], []);
  const regionOptions = useMemo(() => SearchLogic.FILTERS.regions.options || [], []);
  const vendorTypeOptions = useMemo(() => SearchLogic.FILTERS.vendorType.options || [], []);
  const vendorPricingOptions = useMemo(() => SearchLogic.FILTERS.vendorPricing.options || [], []);

  const appliedGuestCount = app.searchFilters?.getFilterData<string>(
    VenueQuickFilterValues.CAPACITY,
  );

  const appliedVendorType = vendorTypeOptions.find(
    (el) => el.value === app.searchFilters?.getFilterData<string>(VendorQuickFilterValues.TYPE),
  )?.label;

  const appliedPricing = vendorPricingOptions.find(
    (el) => el.value === app.searchFilters?.getFilterData<string>(VendorQuickFilterValues.PRICING),
  )?.label;

  const appliedEventType = eventTypeOptions.find(
    (el) =>
      el.value === app.searchFilters?.getFilterData<string>(VenueQuickFilterValues.BEST_USED_FOR),
  )?.label;

  const appliedLocation = regionOptions.find(
    (region) =>
      region.value === app.searchFilters?.getFilterData<string>(VenueQuickFilterValues.REGIONS),
  )?.label;

  return (
    <Stack className={styles.filtersContainer} onClick={onClick}>
      <Stack>
        <Typography className={styles.firstRow} data-testid="type-info">
          {(isVenue ? appliedEventType : appliedVendorType) || "What are you planning?"}
        </Typography>
        <Stack gap={2} direction="row">
          <Typography
            className={cn(styles.secondRow, {
              [styles.applied]: !!appliedGuestCount || !!appliedPricing,
            })}
            data-testid="second-row"
          >
            {getSecondRowText(isVenue, appliedGuestCount, appliedPricing)}
          </Typography>
          {!appliedLocation && <Typography className={styles.thirdRow} data-testid="choose-location">Any location</Typography>}
        </Stack>
      </Stack>
      {appliedLocation && (
        <Typography className={cn(styles.thirdRow, styles.applied)} data-testid="applied-location">{appliedLocation}</Typography>
      )}
    </Stack>
  );
}

export default MobileQuickFiltersInfo;
